import { IQuestion } from '@/interfaces';
import { IChatAskRequest, IInsightsRequest } from '@/interfaces/Chat';
import { IChatHistoryMessage } from '@/interfaces/ChatHistoryMessage';
import { IChatMessage } from '@/interfaces/ChatMessage';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithRefresh } from './base';
import { IInsightsChatMessage } from '@/interfaces/InsightsChatMessage'

export const chatApi = createApi({
  baseQuery: baseQueryWithRefresh,
  tagTypes: ['History'],
  endpoints: (builder) => ({
    fetchChatHistory: builder.mutation<IChatHistoryMessage[], string>({
      query: (id: IQuestion['id']) => ({
        url: `/ai/history/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IChatHistoryMessage[]): IChatHistoryMessage[] => response,
    }),
    fetchInsightsChatHistory: builder.query<IInsightsChatMessage[], string>({
      query: (id: IQuestion['id']) => ({
        url: `/ai/insights-history/${id}`,
        method: 'GET',
        credentials: 'include',
      }),
      providesTags: (results) =>
        results
          ?
          [
            ...results.map(message => ({ type: 'History', id: message.id } as const)),
            { type: 'History', id: 'LIST' }
          ]
          :
          [{ type: 'History', id: 'LIST' }]
    }),
    ask: builder.mutation<IChatMessage, IChatAskRequest>({
      query: (data: IChatAskRequest) => ({
        url: '/ai/ask',
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      transformResponse: (response: IChatMessage): IChatMessage => response,
    }),
    insights: builder.mutation<IChatMessage, IInsightsRequest>({
      query: (data: IInsightsRequest) => ({
        url: '/ai/insights?is_requester=1&include=area_scoring',
        method: 'POST',
        credentials: 'include',
        body: data,
      }),
      transformResponse: (response: IChatMessage): IChatMessage => response,
      invalidatesTags: [{ type: 'History', id: 'LIST' }]
    }),
  }),
  reducerPath: 'chatApi',
});

export const { useFetchChatHistoryMutation, useAskMutation, useInsightsMutation, useFetchInsightsChatHistoryQuery } = chatApi;
