import { createApi } from '@reduxjs/toolkit/query/react';
import { publicQuery } from '@/app/services/base';
import { ICompanyInfo } from '@/interfaces/CompanyInfo';

export const companyInfoApi = createApi({
  baseQuery: publicQuery,
  endpoints: (builder) => ({
    getInfo: builder.query<ICompanyInfo | null, { countryCode: string; registryNumber: string }>({
      query: ({ countryCode, registryNumber }) => ({
        url: `/company/info?code=${countryCode}&registryNumber=${registryNumber}`,
      }),
      transformResponse: (response: ICompanyInfo | null) => response,
    }),
  }),
  reducerPath: 'companyInfoApi',
});

export const { useLazyGetInfoQuery } = companyInfoApi;
