import { IUser } from '@/interfaces';
import { RootState } from '@/types/RootState';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type AuthState = {
  user: IUser | null;
  token: string | null;
  refreshStatus: 'unset' | 'success' | 'failed';
};

const initialState = { user: null, token: null, refreshStatus: 'unset' } as AuthState;

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token } }: PayloadAction<{ user: IUser; token: string }>,
    ) => {
      state.user = user;
      state.token = token;
      state.refreshStatus = 'success';
    },
    clearCredentials: (state) => {
      state.user = null;
      state.token = null;
      state.refreshStatus = 'unset';
    },
    setCurrentUser: (state, { payload }: PayloadAction<IUser | null>) => {
      state.user = payload;
    },
    setRefreshStatus: (state, { payload }: PayloadAction<'success' | 'failed'>) => {
      state.refreshStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearCredentials, () => {
      return initialState;
    });
  },
});

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentCompany = (state: RootState) => state.auth.user?.company;
export const selectAccessToken = (state: RootState) => state.auth.token;
export const selectRefreshStatus = (state: RootState) => state.auth.refreshStatus;
const { actions, reducer } = slice;

export const { setCredentials, clearCredentials, setCurrentUser, setRefreshStatus } = actions;

export default reducer;
