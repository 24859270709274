import { IAdminAssessmentRequest, IAssessment, IAssessmentRequest } from '@/interfaces';
import { TypeToKey } from '@/types';
import { normalizeResponse } from '@/utils/normalize';
import { createApi } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { NormalizedSchema } from 'normalizr';

import { IAssessmentRequestInsights } from '@/types/AssessmentRequestInsights';
import { AssessmentInsights } from '@/types/AssessmentInsights';
import {
  adminAssessmentRequestsSchema,
  assessmentRequestsSchema,
  assessmentSummarySchema,
  assessmentsSchema,
} from '../schema';
import { baseQueryWithRefresh } from './base';
import { IAssessmentRequestOne } from '@/interfaces/AssessmentRequestOne'
import { AssessmentRequestReport } from '@/types/AssessmentRequestReport';

type AssessmentRequestRequest = {
  assessmentRequestId: string;
  include?: string;
};

export const assessmentRequestsApi = createApi({
  baseQuery: baseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    fetchAssessmentRequests: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessmentRequest>, string[]>,
      void
    >({
      query: () => ({
        url: '/assessment-requests',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAssessmentRequest[]) => {
        const data = normalizeResponse<IAssessmentRequest>(response, assessmentRequestsSchema);
        return data;
      },
    }),
    fetchAdminAssessmentRequests: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessmentRequest>, string[]>,
      void
    >({
      query: () => ({
        url: '/admin-assessment-requests',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAdminAssessmentRequest[]) => {
        return normalizeResponse<IAdminAssessmentRequest>(response, adminAssessmentRequestsSchema);
      },
    }),
    fetchAssessmentRequestAssessments: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessment>, string[]>,
      AssessmentRequestRequest
    >({
      query: ({ assessmentRequestId, include }) => ({
        url: `/assessment-requests/${assessmentRequestId}/assessments${
          include ? '?include=' + include : ''
        }`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAssessment[]) => {
        const data = normalizeResponse<IAssessment>(response, assessmentsSchema);
        return data;
      },
    }),
    fetchAdminAssessmentRequestAssessments: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessment>, string[]>,
      AssessmentRequestRequest
    >({
      query: ({ assessmentRequestId, include }) => ({
        url: `/admin-assessment-requests/${assessmentRequestId}/assessments${
          include ? '?include=' + include : ''
        }`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAssessment[]) => {
        return normalizeResponse<IAssessment>(response, assessmentsSchema);
      },
    }),
    fetchAssessmentRequestById: builder.query<
      IAssessmentRequestOne,
      string
    >({
      query: (assessmentRequestId) => ({
        url: `/assessment-requests/${assessmentRequestId}`,
        credentials: 'include',
      }),
      transformResponse: (response: IAssessmentRequestOne) => {
        return response;
      },
    }),
    fetchAdminAssessmentRequestById: builder.query<
      IAssessmentRequestOne,
      string
    >({
      query: (assessmentRequestId) => ({
        url: `/admin-assessment-requests/${assessmentRequestId}`,
        credentials: 'include',
      }),
      transformResponse: (response: IAssessmentRequestOne) => {
        return response;
      },
    }),
    fetchAssessmentRequestInsights: builder.query<
      NormalizedSchema<TypeToKey<AssessmentInsights>, string[]>,
      string
    >({
      query: (assessmentRequestId) => ({
        url: `/assessment-requests/${assessmentRequestId}/insights`,
        credentials: 'include',
      }),
      transformResponse: (response: IAssessmentRequestInsights[]) => {
        return normalizeResponse<IAssessmentRequestInsights>(response, assessmentSummarySchema);
      },
    }),
    fetchAdminAssessmentRequestInsights: builder.query<
      NormalizedSchema<TypeToKey<AssessmentInsights>, string[]>,
      string
    >({
      query: (assessmentRequestId) => ({
        url: `/admin-assessment-requests/${assessmentRequestId}/summary`,
        credentials: 'include',
      }),
      transformResponse: (response: IAssessmentRequestInsights[]) => {
        return normalizeResponse<IAssessmentRequestInsights>(response, assessmentSummarySchema);
      },
    }),
    fetchAssessmentRequestReport: builder.query<AssessmentRequestReport, string>({
      query: (assessmentRequestId) => ({
        url: `/assessment-requests/${assessmentRequestId}/report`,
        credentials: 'include',
      }),
      transformResponse: (response: AssessmentRequestReport) => {
        return response;
      }
    }),
    updateAssessmentRequest: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessment>, string[]>,
      { id: string; payload: Partial<IAssessmentRequest>; locale: string }
    >({
      query: ({ id, payload, locale }) => ({
        url: `/assessment-requests/${id}?locale=${locale}`,
        method: 'PATCH',
        credentials: 'include',
        body: payload,
      }),
      transformResponse: (response: IAssessmentRequest) => {
        const data = normalizeResponse<IAssessmentRequest>([response], assessmentRequestsSchema);
        return data;
      },
    }),
    downloadDetailedData: builder.mutation<null, { id: string; name: string; year: number }>({
      queryFn: async ({ id, name, year }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/admin-assessment-requests/${id}/download-detailed-data`,
          credentials: 'include',
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL || window.webkitURL;
        const blobPDF = url.createObjectURL(result.data as Blob);
        hiddenElement.href = blobPDF;
        hiddenElement.target = '_blank';
        hiddenElement.download = `${name}_${year}.xlsx`;
        hiddenElement.click();
        return { data: null };
      },
    }),
  }),
  reducerPath: 'assessmentRequestsApi',
});

export const {
  useFetchAssessmentRequestsMutation,
  useFetchAdminAssessmentRequestsMutation,
  useFetchAssessmentRequestAssessmentsMutation,
  useFetchAdminAssessmentRequestAssessmentsMutation,
  useFetchAssessmentRequestByIdQuery,
  useFetchAdminAssessmentRequestByIdQuery,
  useFetchAssessmentRequestInsightsQuery,
  useFetchAdminAssessmentRequestInsightsQuery,
  useFetchAssessmentRequestReportQuery,
  useDownloadDetailedDataMutation,
} = assessmentRequestsApi;
