import { ITopic } from '@/interfaces/Topic';
import { TypeToKey } from '@/types';
import { normalizeResponse } from '@/utils/normalize';
import { createApi } from '@reduxjs/toolkit/query/react';
import { NormalizedSchema } from 'normalizr';
import { topicsSchema } from '../schema';
import { baseQueryWithRefresh } from './base';

type FetchTopicsRequest = {
  assessmentId?: string;
  assessmentRequestId?: string;
  include?: string;
};

export const topicsApi = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    fetchTopics: builder.mutation<
      NormalizedSchema<TypeToKey<ITopic>, string[]>,
      FetchTopicsRequest
    >({
      query: (payload) => ({
        url: `/topics?assessmentId=${payload.assessmentId}${
          payload.include ? '&include=' + payload.include : ''
        }`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: ITopic[]) => {
        const sortedResponse = response.sort((a, b) => a.sorting - b.sorting);
        return normalizeResponse<ITopic>(sortedResponse, topicsSchema);
      },
    }),
    fetchAdminTopics: builder.mutation<
      NormalizedSchema<TypeToKey<ITopic>, string[]>,
      FetchTopicsRequest
    >({
      query: (payload) => ({
        url: `/admin-topics?assessmentId=${payload.assessmentId}${
          payload.include ? '&include=' + payload.include : ''
        }`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: ITopic[]) => {
        const sortedResponse = response.sort((a, b) => a.sorting - b.sorting);
        return normalizeResponse<ITopic>(sortedResponse, topicsSchema);
      },
    }),
    fetchAssessmentRequestTopics: builder.mutation<
      NormalizedSchema<TypeToKey<ITopic>, string[]>,
      FetchTopicsRequest
    >({
      query: (payload) => ({
        url: `/topics?assessmentRequestId=${payload.assessmentRequestId}${
          payload.include ? '&include=' + payload.include : ''
        }`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: ITopic[]) => {
        const sortedResponse = response.sort((a, b) => a.sorting - b.sorting);
        return normalizeResponse<ITopic>(sortedResponse, topicsSchema);
      },
    }),
    fetchAdminAssessmentRequestTopics: builder.mutation<
      NormalizedSchema<TypeToKey<ITopic>, string[]>,
      FetchTopicsRequest
    >({
      query: (payload) => ({
        url: `/admin-topics?assessmentRequestId=${payload.assessmentRequestId}${
          payload.include ? '&include=' + payload.include : ''
        }`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: ITopic[]) => {
        const sortedResponse = response.sort((a, b) => a.sorting - b.sorting);
        return normalizeResponse<ITopic>(sortedResponse, topicsSchema);
      },
    }),
  }),
  reducerPath: 'topicsApi',
});

export const {
  useFetchTopicsMutation,
  useFetchAdminTopicsMutation,
  useFetchAssessmentRequestTopicsMutation,
  useFetchAdminAssessmentRequestTopicsMutation,
} = topicsApi;
