import { IAdminAssessment, IAssessment } from '@/interfaces';
import { IAssessmentRequestOne } from '@/interfaces/AssessmentRequestOne';
import { TypeToKey } from '@/types';
import { normalizeResponse } from '@/utils/normalize';
import { createApi } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { NormalizedSchema } from 'normalizr';

import { IAssessmentOne } from '@/interfaces/AssessmentOne';
import { IAssessmentRequestInvitationTokenRaw } from '@/interfaces/AssessmentRequestInvitationTokenRaw';
import { AssessmentInsights } from '@/types/AssessmentInsights';
import {
  adminAssessmentsDetailsSchema,
  adminAssessmentsSchema,
  assessmentSummarySchema,
  assessmentsDetailsSchema,
  assessmentsSchema,
} from '../schema';
import { baseQueryWithRefresh } from './base';

type FetchOneAssessment = {
  assessmentId: string;
  include?: string;
};

export const assessmentsApi = createApi({
  baseQuery: baseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    fetchAssessments: builder.mutation<NormalizedSchema<TypeToKey<IAssessment>, string[]>, void>({
      query: () => ({
        url: '/assessments',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAssessment[]) => {
        return normalizeResponse<IAssessment>(response, assessmentsSchema);
      },
    }),
    fetchRequesterAssessmentById: builder.query<IAssessmentOne, string>({
      query: (assessmentId) => ({
        url: `/assessments/${assessmentId}?is_requester=1&include=area_scoring`,
        credentials: 'include',
      }),
      transformResponse: (response: IAssessmentOne) => {
        return response;
      },
    }),
    fetchRequesterAssessments: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessment>, string[]>,
      void
    >({
      query: () => ({
        url: '/assessments?is_requester=1&include=area_scoring',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAssessment[]) => {
        return normalizeResponse<IAssessment>(response, assessmentsSchema);
      },
    }),
    fetchAdminAssessments: builder.query<
      NormalizedSchema<TypeToKey<IAdminAssessment>, string[]>,
      void
    >({
      query: () => ({
        url: '/admin-assessments?include=area_scoring',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAdminAssessment[]) => {
        return normalizeResponse<IAdminAssessment>(response, adminAssessmentsSchema);
      },
    }),
    fetchAssessmentById: builder.query<IAssessmentOne, FetchOneAssessment>({
      query: ({ assessmentId, include }) => ({
        url: `/assessments/${assessmentId}${include ? '?include=' + include : ''}`,
        credentials: 'include',
      }),
      transformResponse: (response: IAssessmentOne) => {
        return response;
      },
    }),
    fetchAdminAssessmentById: builder.query<IAssessmentOne, string>({
      query: (assessmentId) => ({
        url: `/admin-assessments/${assessmentId}`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAssessmentOne) => {
        return response;
      },
    }),
    updateAssessment: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessment>, string[]>,
      { id: string; payload: Partial<IAssessment>; locale: string }
    >({
      query: ({ id, payload, locale }) => ({
        url: `/assessments/${id}?locale=${locale}`,
        method: 'PATCH',
        credentials: 'include',
        body: payload,
      }),
      transformResponse: (response: IAssessment) => {
        return normalizeResponse<IAssessment>([response], assessmentsSchema);
      },
    }),
    acceptAssessmentRequest: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessment>, string[]>,
      { token: string; locale: string }
    >({
      query: ({ token, locale }) => ({
        url: `/assessment-requests/accept`,
        method: 'POST',
        credentials: 'include',
        body: { token, locale },
      }),
      transformResponse: (response: IAssessment) => {
        return normalizeResponse<IAssessment>([response], assessmentsSchema);
      },
    }),
    fetchAssessmentRequestByInvitationToken: builder.mutation<IAssessmentRequestOne, string>({
      query: (token) => ({
        url: `/assessment-requests/by-invitation?token=${token}`,
        method: 'GET',
        credentials: 'include',
      }),
    }),
    fetchAssessmentRequestInvitation: builder.mutation<
      IAssessmentRequestInvitationTokenRaw,
      string
    >({
      query: (token) => ({
        url: `/assessment-requests/get-invitation?token=${token}`,
        method: 'GET',
        credentials: 'include',
      }),
    }),
    fetchAssessmentDetails: builder.mutation<
      NormalizedSchema<TypeToKey<IAssessment>, string[]>,
      FetchOneAssessment
    >({
      query: ({ assessmentId, include }) => ({
        url: `/assessments/${assessmentId}/details${include ? '?include=' + include : ''}`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IAssessment) => {
        return normalizeResponse<IAssessment>([response], assessmentsDetailsSchema);
      },
    }),
    deleteAssessment: builder.mutation<void, string>({
      query: (assessmentId) => ({
        url: `/admin-assessments/${assessmentId}`,
        method: 'DELETE',
        credentials: 'include',
      }),
    }),
    fetchAssessmentSummary: builder.query<
      NormalizedSchema<TypeToKey<AssessmentInsights>, string[]>,
      string
    >({
      query: (assessmentId) => ({
        url: `/assessments/${assessmentId}/summary`,
        credentials: 'include',
      }),
      transformResponse: (response: AssessmentInsights[]) => {
        return normalizeResponse<AssessmentInsights>(response, assessmentSummarySchema);
      },
    }),
    fetchAdminAssessmentSummary: builder.query<
      NormalizedSchema<TypeToKey<AssessmentInsights>, string[]>,
      string
    >({
      query: (assessmentId) => ({
        url: `/admin-assessments/${assessmentId}/summary`,
        credentials: 'include',
      }),
      transformResponse: (response: AssessmentInsights[]) => {
        return normalizeResponse<AssessmentInsights>(response, assessmentSummarySchema);
      },
    }),
    fetchAdminAssessmentDetails: builder.query<
      NormalizedSchema<TypeToKey<IAssessment>, string[]>,
      string
    >({
      query: (assessmentId) => ({
        url: `/admin-assessments/${assessmentId}/details`,
        credentials: 'include',
      }),
      transformResponse: (response: IAdminAssessment) => {
        return normalizeResponse<IAdminAssessment>([response], adminAssessmentsDetailsSchema);
      },
    }),
  }),
  reducerPath: 'assessmentsApi',
});

export const {
  useFetchAssessmentsMutation,
  useFetchRequesterAssessmentsMutation,
  useFetchAdminAssessmentsQuery,
  useFetchRequesterAssessmentByIdQuery,
  useFetchAssessmentByIdQuery,
  useUpdateAssessmentMutation,
  useAcceptAssessmentRequestMutation,
  useFetchAssessmentRequestByInvitationTokenMutation,
  useFetchAssessmentDetailsMutation,
  useFetchAdminAssessmentDetailsQuery,
  useFetchAssessmentRequestInvitationMutation,
  useFetchAssessmentSummaryQuery,
  useFetchAdminAssessmentSummaryQuery,
  useFetchAdminAssessmentByIdQuery,
  useDeleteAssessmentMutation,
} = assessmentsApi;
