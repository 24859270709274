import { AnswerUpdateDto } from '@/app/services/answers';
import { IAnswer, IAnswerEdit, IChoice, IQuestion } from '@/interfaces';
import { IAnswerError, IAnswerErrorValues } from '@/interfaces/AnswerError';
import { IAssessmentOne } from '@/interfaces/AssessmentOne';
import { IAssessmentRequestOne } from '@/interfaces/AssessmentRequestOne';
import { QuestionType } from '@/types';
import { isEmpty, isEqual } from 'lodash';

export const composeAnswer = (
  currentAnswer: IAnswer | undefined,
  assessment: IAssessmentOne,
  question: IQuestion,
): IAnswerEdit => {
  let ret: IAnswerEdit;
  if (currentAnswer) {
    ret = {
      ...currentAnswer,
      isChanged: false,
      ...(currentAnswer.documentUrl ? { documentName: currentAnswer.documentUrl } : {}),
    };
  } else {
    const initialChoicesValue: {
      [key: string]: {
        checked: boolean;
        numberValue: number | null;
        textValue: string | null;
      };
    } = {};
    if (question.questionType === QuestionType.MULTIPLE_CHOICE) {
      question.choices?.forEach((item) => {
        initialChoicesValue[item] = {
          checked: false,
          numberValue: null,
          textValue: null,
        };
      });
    }
    ret = {
      id: undefined,
      version: undefined,
      year: assessment.assessmentRequest.year,
      questionType: question.questionType,
      answerableId: undefined,
      isFinalized: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      questionId: question.id,
      companyId: undefined,
      textAnswer: { value: '' },
      numberAnswer: { value: undefined },
      booleanAnswer: { value: undefined },
      choiceAnswer: { value: undefined },
      multipleChoiceAnswer: { value: initialChoicesValue },
      documents: [],
      documentUrl: undefined,
      choiceExplanation: undefined,
      isChanged: false,
    };
  }
  return ret;
};

export const composeRemovedAnswer = (
  currentAnswer: IAnswer | undefined,
  question: IQuestion,
  assessmentRequest: IAssessmentRequestOne,
): IAnswerEdit => {
  let ret: IAnswerEdit;
  const initialChoicesValue: {
    [key: string]: {
      checked: boolean;
      numberValue: number | null;
      textValue: string | null;
    };
  } = {};
  if (question.questionType === QuestionType.MULTIPLE_CHOICE) {
    question.choices?.forEach((item) => {
      initialChoicesValue[item] = {
        checked: false,
        numberValue: null,
        textValue: null,
      };
    });
  }

  if (!isEmpty(currentAnswer)) {
    ret = {
      ...currentAnswer,
      textAnswer: { value: '' },
      numberAnswer: { value: undefined },
      booleanAnswer: { value: undefined },
      choiceAnswer: { value: undefined },
      multipleChoiceAnswer: { value: initialChoicesValue },
      documents: [],
      documentUrl: undefined,
      choiceExplanation: undefined,
      isChanged: true,
    };
  } else {
    ret = {
      id: undefined,
      version: undefined,
      year: assessmentRequest.year,
      questionType: question.questionType,
      questionId: question.id,
      createdAt: undefined,
      updatedAt: undefined,
      answerableId: undefined,
      isFinalized: undefined,
      companyId: undefined,
      textAnswer: { value: '' },
      numberAnswer: { value: undefined },
      booleanAnswer: { value: undefined },
      choiceAnswer: { value: undefined },
      multipleChoiceAnswer: { value: initialChoicesValue },
      documents: [],
      documentUrl: undefined,
      choiceExplanation: undefined,
      isChanged: true,
    };
  }
  return ret;
};

export const hasAnswerChanged = (answer: IAnswer, editedAnswer: IAnswerEdit) => {
  const {
    booleanAnswer,
    documentUrl,
    choiceAnswer,
    choiceExplanation,
    multipleChoiceAnswer,
    numberAnswer,
    textAnswer,
    documents,
  } = answer;
  const {
    booleanAnswer: booleanAnswerEdited,
    documentUrl: documentUrlEdited,
    choiceAnswer: choiceAnswerEdited,
    choiceExplanation: choiceExplanationEdited,
    multipleChoiceAnswer: multipleChoiceAnswerEdited,
    numberAnswer: numberAnswerEdited,
    textAnswer: textAnswerEdited,
    documents: documentsEdited,
  } = editedAnswer;

  if (
    booleanAnswer?.value !== booleanAnswerEdited?.value ||
    documentUrl !== documentUrlEdited ||
    choiceAnswer?.value !== choiceAnswerEdited?.value ||
    choiceExplanation !== choiceExplanationEdited ||
    !isEqual(multipleChoiceAnswer, multipleChoiceAnswerEdited) ||
    numberAnswer?.value !== numberAnswerEdited?.value ||
    textAnswer?.value !== textAnswerEdited?.value ||
    documents.length !== documentsEdited.length
  ) {
    return true;
  }

  const docIds = documents?.map(({ id }) => id);
  const docIdsEdited = documentsEdited?.map(({ id }) => id);
  const hasNewDocuments = docIdsEdited?.some((idEdited) => docIds.indexOf(idEdited) === -1);
  if (hasNewDocuments) {
    return true;
  }

  return false;
};

export const prepareAnswerUpdates = (
  currentQuestion: IQuestion | null,
  currentQuestionAnswers: { [key: string]: IAnswerEdit },
  answers: { [key: string]: IAnswer },
  childQuestions: { [key: string]: IQuestion },
): AnswerUpdateDto => {
  const updates: AnswerUpdateDto = {};

  if (!currentQuestion) {
    return updates;
  }

  const inserted: IAnswerEdit[] = [];
  const updated: IAnswerEdit[] = [];
  const deleted: string[] = [];

  // main question
  const mainQuestionAnswer = currentQuestionAnswers[currentQuestion.id];
  const mainQuestionOldAnswer = mainQuestionAnswer.id ? answers[mainQuestionAnswer.id] : null;

  if (!mainQuestionOldAnswer) {
    inserted.push(mainQuestionAnswer);
  } else if (hasAnswerChanged(mainQuestionOldAnswer, mainQuestionAnswer)) {
    updated.push(mainQuestionAnswer);
  }

  // child questions
  const answerValues = Object.values(currentQuestionAnswers)?.filter((item) =>
    currentQuestion?.childQuestions.includes(item.questionId),
  );

  for (const answer of answerValues) {
    if (answer.id) {
      if (
        mainQuestionAnswer.questionType === QuestionType.BOOLEAN &&
        mainQuestionAnswer.booleanAnswer?.value !==
          childQuestions[answer.questionId].showOnParentBooleanValue
      ) {
        deleted.push(answer.id);
      } else {
        const oldAnswer = answers[answer.id];
        if (hasAnswerChanged(oldAnswer, answer)) {
          updated.push(answer);
        } else {
          // do nothing... nothing changed
        }
      }
    } else {
      if (
        (answer.questionType === QuestionType.BOOLEAN &&
          answer.booleanAnswer?.value !== undefined) ||
        (answer.questionType === QuestionType.CHOICE && answer.choiceAnswer?.value) ||
        (answer.questionType === QuestionType.MULTIPLE_CHOICE &&
          answer.multipleChoiceAnswer?.value &&
          Object.values(answer.multipleChoiceAnswer?.value).find((mc) => mc.checked)) ||
        (answer.questionType === QuestionType.NUMBER && answer.numberAnswer?.value !== undefined) ||
        (answer.questionType === QuestionType.TEXT && answer.textAnswer?.value)
      ) {
        inserted.push(answer);
      }
    }
  }
  if (inserted.length > 0) {
    updates['insert'] = inserted;
  }

  if (updated.length > 0) {
    updates['update'] = updated;
  }

  if (deleted.length > 0) {
    updates['delete'] = deleted;
  }

  return updates;
};

export const getRelevantAnswers = (
  currentQuestion: IQuestion | null,
  currentQuestionAnswers: { [key: string]: IAnswerEdit },
  childQuestions: { [key: string]: IQuestion },
): IAnswerEdit[] => {
  if (!currentQuestion) {
    return [];
  }

  const currentQuestionMultipleChoiceAnswers =
    currentQuestionAnswers[currentQuestion.id]?.multipleChoiceAnswer?.value;
  const checkedMultipleChoiceAnswerIds = Object.keys(
    currentQuestionMultipleChoiceAnswers || {},
  ).filter((key) => currentQuestionMultipleChoiceAnswers[key].checked);

  const ret: IAnswerEdit[] = [currentQuestionAnswers[currentQuestion.id]];
  currentQuestion.childQuestions
    ?.map((c) => childQuestions[c])
    .map((item) => {
      if (
        (currentQuestion.questionType === QuestionType.BOOLEAN &&
          item.showOnParentBooleanValue ==
            currentQuestionAnswers[currentQuestion.id]?.booleanAnswer?.value &&
          currentQuestionAnswers[currentQuestion.id]?.booleanAnswer?.value !== undefined &&
          currentQuestionAnswers[currentQuestion.id]?.booleanAnswer?.value !== null) ||
        (currentQuestion.questionType === QuestionType.CHOICE &&
          item.showOnParentChoiceValue?.includes(
            currentQuestionAnswers[currentQuestion.id]?.choiceAnswer?.value || '',
          )) ||
        (currentQuestion.questionType === QuestionType.MULTIPLE_CHOICE &&
          checkedMultipleChoiceAnswerIds.some((id) => item.showOnParentChoiceValue?.includes(id)))
      ) {
        if (currentQuestionAnswers[item.id]) {
          ret.push(currentQuestionAnswers[item.id]);
        }
      }
    });

  return ret;
};

export const validateAnswers = (
  currentQuestion: IQuestion | null,
  currentQuestionAnswers: { [key: string]: IAnswerEdit },
  choices: { [key: string]: IChoice },
  childQuestions: { [key: string]: IQuestion },
) => {
  const errorList: IAnswerError = {};
  const relevantAnswers = getRelevantAnswers(
    currentQuestion,
    currentQuestionAnswers,
    childQuestions,
  );

  if (!currentQuestion) {
    return {};
  }

  const relevantQuestions: { [key: string]: IQuestion } = {
    [currentQuestion.id]: currentQuestion,
  };

  const MAX_SAFE_NUMBER_VALUE = BigInt('9223372036854775807');

  currentQuestion.childQuestions
    ?.map((id) => childQuestions[id])
    .forEach((q) => {
      relevantQuestions[q.id] = q;
    });

  relevantAnswers.forEach((a) => {
    if (a?.questionType === QuestionType.BOOLEAN) {
      const err: IAnswerErrorValues = {};
      if (a.booleanAnswer?.value === undefined || a.booleanAnswer?.value === null) {
        err.error = 'error.chooseOne';
      }
      if (
        relevantQuestions?.[a.questionId]?.isDocumentRequired &&
        a.booleanAnswer?.value &&
        !a.documents.length &&
        !a.documentUrl
      ) {
        err.documentError = 'error.documentMissing';
      }
      if (!isEmpty(err)) {
        errorList[a.questionId] = err;
      }
    }

    if (a?.questionType === QuestionType.CHOICE) {
      if (a.choiceAnswer?.value === undefined || a.choiceAnswer?.value === null) {
        errorList[a.questionId] = { error: 'error.chooseOne' };
      } else if (
        a.choiceAnswer?.value &&
        choices?.[a.choiceAnswer?.value].requireExplanation &&
        !a.choiceExplanation
      ) {
        errorList[a.questionId] = { error: 'error.additionalInformationMissing' };
      }
    }

    if (a?.questionType === QuestionType.MULTIPLE_CHOICE) {
      if (
        !a.multipleChoiceAnswer?.value ||
        Object.values(a.multipleChoiceAnswer.value).every((mc) => !mc.checked)
      ) {
        errorList[a.questionId] = { error: 'error.checkOne' };
      }
    }

    if (a?.questionType === QuestionType.NUMBER) {
      if (
        a.numberAnswer?.value === undefined ||
        a.numberAnswer?.value === null ||
        a.numberAnswer?.value.toString() === 'NaN'
      ) {
        errorList[a.questionId] = { error: 'error.valueMissing' };
      } else if ((a.numberAnswer?.value || 0) < 0) {
        errorList[a.questionId] = { error: 'error.negativeValue' };
      } else if (
        (a.numberAnswer?.value || 0) > 100 &&
        relevantQuestions[a.questionId]?.uom === '%'
      ) {
        errorList[a.questionId] = { error: 'error.maxValueReachedPercent' };
      } else if (BigInt(a.numberAnswer?.value || 0) > MAX_SAFE_NUMBER_VALUE) {
        errorList[a.questionId] = { error: 'error.maxValueReached' };
      }
    }

    if (a?.questionType === QuestionType.TEXT && isEmpty(a.textAnswer?.value)) {
      errorList[a.questionId] = { error: 'error.valueMissing' };
    }
  });

  return errorList;
};
