import { ICompany } from '@/interfaces';
import { TypeToKey } from '@/types';
import { normalizeResponse } from '@/utils/normalize';
import { createApi } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { NormalizedSchema } from 'normalizr';
import { adminCompaniesSchema } from '../schema';

import { baseQueryWithRefresh } from './base';

export const companiesApi = createApi({
  baseQuery: baseQueryWithRefresh,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    fetchAdminCompanies: builder.mutation<NormalizedSchema<TypeToKey<ICompany>, string[]>, void>({
      query: () => ({
        url: '/admin-companies',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: ICompany[]) => {
        return normalizeResponse<ICompany>(response, adminCompaniesSchema);
      },
    }),
  }),
  reducerPath: 'companiesApi',
});

export const { useFetchAdminCompaniesMutation } = companiesApi;
