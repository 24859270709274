import { MimeTypesMap, SupportedFileType } from '@/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type UploadDocumentRequest = {
  url: string;
  file: File;
  extension: SupportedFileType;
};

export const awsApi = createApi({
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    uploadDocument: builder.mutation<void, UploadDocumentRequest>({
      query: ({ url, file, extension }) => ({
        url,
        method: 'PUT',
        headers: {
          'Content-Type': MimeTypesMap.get(extension),
        },
        body: file,
      }),
    }),
  }),
  reducerPath: 'awsApi',
});

export const { useUploadDocumentMutation } = awsApi;
