import { IUser } from '@/interfaces';
import { TypeToKey } from '@/types';
import { BusinessActivity } from '@/types/BusinessActivity';
import { Message } from '@/types/Message';
import { RootState } from '@/types/RootState';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { NormalizedSchema } from 'normalizr';
import { clearCredentials } from '../auth/authSlice';

type UsersState = {
  entities: TypeToKey<IUser> & TypeToKey<Message> & TypeToKey<BusinessActivity>;
  ids: string[];
};

const initialState = {
  entities: { users: {}, messages: {}, businessActivities: {} },
  ids: [],
} as UsersState;

export const logout = createAction('user/logout');

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, { payload }: PayloadAction<NormalizedSchema<TypeToKey<IUser>, string[]>>) => {
      state.ids = payload.result;
      state.entities = {
        messages: state.entities.messages,
        businessActivities: state.entities.businessActivities,
        ...payload.entities,
      };
    },
    setAdminUsers: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IUser>, string[]>>,
    ) => {
      state.ids = payload.result;
      state.entities = {
        messages: state.entities.messages,
        businessActivities: state.entities.businessActivities,
        ...payload.entities,
      };
    },
    setMessages: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<Message>, string[]>>,
    ) => {
      state.entities = {
        users: state.entities.users,
        businessActivities: state.entities.businessActivities,
        ...payload.entities,
      };
    },
    setBusinessActivities: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<BusinessActivity>, string[]>>,
    ) => {
      state.entities = {
        users: state.entities.users,
        messages: state.entities.messages,
        ...payload.entities,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearCredentials, () => {
      return initialState;
    });
  },
});

export const selectUserIds = (state: RootState) => state.users.ids;
export const selectUsers = (state: RootState) => state.users.entities.users;
export const selectAdminUsers = (state: RootState) => state.users.entities.users;
export const selectMessages = (state: RootState) => state.users.entities.messages;
export const selectBusinessActivities = (state: RootState) =>
  state.users.entities.businessActivities;

const { actions, reducer } = slice;

export const { setMessages, setBusinessActivities, setAdminUsers } = actions;

export default reducer;
