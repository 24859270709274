import { IUser } from '@/interfaces';
import { TypeToKey } from '@/types';
import { BusinessActivity } from '@/types/BusinessActivity';
import { Message } from '@/types/Message';
import { normalizeResponse } from '@/utils/normalize';
import { createApi } from '@reduxjs/toolkit/query/react';
import { NormalizedSchema } from 'normalizr';
import { adminUsersSchema, businessActivitiesSchema, messagesSchema } from '../schema';
import { baseQueryWithRefresh } from './base';

export const usersApi = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    fetchCurrentUser: builder.mutation<IUser, void>({
      query: () => ({
        url: '/users/current-user',
        method: 'GET',
        credentials: 'include',
      }),
    }),
    logout: builder.mutation<null, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
        credentials: 'include',
      }),
    }),
    fetchAdminUsers: builder.mutation<NormalizedSchema<TypeToKey<IUser>, string[]>, void>({
      query: () => ({
        url: '/admin-users',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (res: IUser[]) => {
        return normalizeResponse<IUser>(res, adminUsersSchema);
      },
    }),
    verifyUser: builder.mutation<void, IUser['id']>({
      query: (userId) => ({
        url: `/admin-users/${userId}/verify`,
        method: 'PATCH',
        credentials: 'include',
      }),
    }),
    fetchUnreadMessages: builder.mutation<NormalizedSchema<TypeToKey<Message>, string[]>, void>({
      query: () => ({
        url: '/users/unread-messages',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (res: Message[]) => normalizeResponse<Message>(res, messagesSchema),
    }),
    setMessageAsRead: builder.mutation<void, string>({
      query: (id) => ({
        url: '/users/set-message-as-read',
        method: 'POST',
        body: { messageId: id },
        credentials: 'include',
      }),
    }),
    fetchBusinessActivities: builder.mutation<
      NormalizedSchema<TypeToKey<BusinessActivity>, string[]>,
      void
    >({
      query: () => ({
        url: '/companies/business-activities',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: BusinessActivity[]) => {
        return normalizeResponse<BusinessActivity>(response, businessActivitiesSchema);
      },
    }),
    setCompanyBusinessActivities: builder.mutation<void, string[]>({
      query: (businessActivities) => ({
        url: '/companies/set-company-business-activities',
        method: 'POST',
        body: { businessActivities },
        credentials: 'include',
      }),
    }),
  }),
  reducerPath: 'usersApi',
});

export const {
  useFetchCurrentUserMutation,
  useLogoutMutation,
  useFetchUnreadMessagesMutation,
  useSetMessageAsReadMutation,
  useSetCompanyBusinessActivitiesMutation,
  useFetchBusinessActivitiesMutation,
  useFetchAdminUsersMutation,
  useVerifyUserMutation,
} = usersApi;
