import { IQuestion } from '@/interfaces';
import { TypeToKey } from '@/types';
import { normalizeResponse } from '@/utils/normalize';
import { createApi } from '@reduxjs/toolkit/query/react';
import { NormalizedSchema } from 'normalizr';
import { questionsSchema } from '../schema';
import { baseQueryWithRefresh } from './base';

interface FetchQuestionsRequest {
  assessmentId: string;
}

export const questionsApi = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    fetchQuestions: builder.mutation<
      NormalizedSchema<TypeToKey<IQuestion>, string[]>,
      FetchQuestionsRequest
    >({
      query: ({ assessmentId }) => ({
        url: `/questions?assessmentId=${assessmentId}`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IQuestion[]) => {
        const data = normalizeResponse<IQuestion>(response, questionsSchema);

        return data;
      },
    }),
  }),
  reducerPath: 'questionsApi',
});

export const { useFetchQuestionsMutation } = questionsApi;
