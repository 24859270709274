import { useFetchCurrentUserMutation } from '@/app/services/users';
import { useAppDispatch } from '@/app/store';
import { selectRefreshStatus, setCurrentUser } from '@/features/auth/authSlice';
import { changeLocation, selectLocation } from '@/features/global/globalSlice';
import { logger } from '@/utils/logger';
import { LoggerContext } from '@/utils/logger.context';
import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import React, { ReactNode, createContext, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

interface CurrentUserProviderProps {
  children: ReactNode;
}

const CurrentUserContext = createContext({});

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { locale, push } = useRouter();
  const pathname = usePathname();
  const urlParams = useSearchParams();
  const refreshStatus = useSelector(selectRefreshStatus);

  const location = useSelector(selectLocation);

  const [fetchCurrentUser] = useFetchCurrentUserMutation();

  const redirectToLoginConditional = useCallback(() => {
    const token = urlParams?.get('token');
    const pathExcludedFromRedirectToLogin = [
      '/login',
      '/login/reset-password',
      '/signup',
      '/signup/verify',
      '/assessment-request',
    ];

    if (!pathExcludedFromRedirectToLogin.includes(pathname!) && !token) {
      push('/login', '/login', { locale });
    }
  }, [locale, pathname, urlParams, push]);

  const posthog = usePostHog();

  useEffect(() => {
    const loadCurrentUser = async () => {
      try {
        const fetchedUser = await fetchCurrentUser().unwrap();
        if (!fetchedUser) {
          throw new Error('Request failed');
        }
        dispatch(setCurrentUser(fetchedUser));

        posthog.identify(fetchedUser?.id, {
          email: fetchedUser.email,
          firstName: fetchedUser.firstName,
          lastName: fetchedUser.lastName,
          companyId: fetchedUser.company.id,
          companyName: fetchedUser.company.name,
          companyRegistryNumber: fetchedUser.company.registryNumber,
          companyIsRequester: fetchedUser.company.isRequester,
          companyNaceCode: fetchedUser.company.naceCode,
        });
      } catch (err) {
        logger.error(err, { context: LoggerContext.currentUserProvider });
        dispatch(setCurrentUser(null));
        posthog.reset();
        redirectToLoginConditional();
      }
    };

    if (refreshStatus !== 'failed') {
      loadCurrentUser();
    } else {
      posthog.reset();
      redirectToLoginConditional();
    }
  }, [refreshStatus, pathname, fetchCurrentUser, dispatch, redirectToLoginConditional, posthog]);

  useEffect(() => {
    if (location && push) {
      push(location.path, location.path, { locale: location.locale });
      dispatch(changeLocation(null));
    }
  }, [push, location, dispatch]);

  return <CurrentUserContext.Provider value={{}}>{children}</CurrentUserContext.Provider>;
};
