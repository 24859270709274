import { IQuestion } from '@/interfaces';
import { IChatHistoryMessage } from '@/interfaces/ChatHistoryMessage';
import { IChatMessage } from '@/interfaces/ChatMessage';
import { RootState } from '@/types/RootState';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IChatHistoryPayload {
  id: IQuestion['id'];
  history: IChatHistoryMessage[];
}

interface IChatHistoryPushPayload {
  id: IQuestion['id'];
  from: string[];
  message: IChatMessage;
}

const slice = createSlice({
  name: 'chatHistory',
  initialState: {
    history: <{ [key: string]: IChatHistoryMessage[] }>{},
  },
  reducers: {
    setChatHistory(state, { payload }: PayloadAction<IChatHistoryPayload>) {
      state.history[payload.id] = payload.history;
    },
    pushToChatHistory(state, { payload }: PayloadAction<IChatHistoryPushPayload>) {
      state.history[payload.id]?.push({
        id: payload.from,
        kwargs: {
          content: payload.message.text,
        },
      });
    },
  },
});

export const { setChatHistory, pushToChatHistory } = slice.actions;

export default slice.reducer;

export const selectCurrentChatHistory = (id: string | null) =>
  createSelector(
    (state: RootState) => ({
      questionId: state.questions.currentQuestionId,
      history: state.chat.history,
    }),
    ({ questionId, history }) => {
      if (!id) {
        id = questionId;
      }
      if (!id) {
        return;
      }
      if (!Object.hasOwn(history, id)) {
        return;
      }
      return history[id];
    },
  );
